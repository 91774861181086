import * as React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"

const StoreLocator = (data) => {
  if (!data) return null

  return (
    <Layout>
      <Seo title={"Store Locator"} />
      <iframe
        src="https://www.closeby.co/embed/2ee370173413ebf2938fc6fe1d78bf0e"
        width="100%"
        height="600"
        style={{ height: "600px", border: "none" }}
      ></iframe>
    </Layout>
  )
}

export default StoreLocator
